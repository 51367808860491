export const generateTotalQuantityAndPriceStart = (listOrder = []) => {
    let output = {
        quantity: 0,
        price: 0
    }

    let quantity = 0;

    if (listOrder.length > 0){
        output.price = listOrder[0]?.price ?? listOrder[0]?.price_each_unit ?? listOrder[0]?.price_per_unit;
        listOrder.map((orderData, indexOrder) => {
            quantity+= parseInt(orderData?.quantity);
        })
    }

    output['quantity'] = quantity;

    return output;
}