import axios from 'axios';
import Cookies from 'js-cookie';
import common from './Common';

const basicToken =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiODRmOGFmYWZhMDcyYWI3OTVlYWQ0NWZmNTNhODZiZDFjN2NmYWM1NzYwODkyY2YzNGY2ZTNiMTBmNjQ2ZDVkZDMxYjQxOGE0MmU2ZTRhM2IiLCJpYXQiOjE2Nzc4MDg0NjUsIm5iZiI6MTY3NzgwODQ2NSwiZXhwIjoxNjc3ODM3MjY1LCJzdWIiOiIzOSIsInNjb3BlcyI6W119.uRo4B83PeYT99Q7pmw2n3DwmZys8I7rdTJk7A7VKoHIxDJCiSqLi2WV3NY_gsOxWjWFDsQ-6FERmSXdyMfbmgxJSoNvG8RjphzHHgaxgJ1SgfSbmOVM47b8m_Oo49MfeoQVIdi60gBQh0dFjlALGxfrGqCdAeH7NZWouaZoZ7MkxhT_GTYZZyKzpXg9hPgJKuLAG3bbzsGHN6qrm0cQgriwmURvfncSmqNw8srRS1J9MQrmwbJuz_rUC-scu36zmlP2uDZdAcO6mkNckrPZkItL1J2nVxWmREGd7L3__yULsOfRTvcDPLaeAiskEJDsbKNEiuDhTdq4rl-3zI8xYAQCdntOyc2NQeADPLeJBsEvzpAUS1MF1QrIztAE5BIVjjmKIbb3-g-8fYTbxlSW-1hgl2D4xndVvuwWhqo8aZwKdkxe4MVDZN2W6ElVMiSt5x5prtGepQc1nMiZhzTNeqJ6m8EaMCls33VXki9KUh2LK1DDUY9Gbuc3GHe5xcT--1oczUYjzSv9ewsRII0GFJ_AWTtSNakCwv4gvjzeVgkHKC9SwADv7d3ZxfUkezngfMP4Duq8gQ7plJA-W37IttBWCq42-Aws_5hWvwltrsREz8nnB2fdS-T4gDuYmFRbs2Lj4hWtdnv2nKbe7yDVuBGTsP0xDbUGASPo-PsxjzfU';

export const setAuthToken = (token = basicToken) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};

export const setHeader = (name, value) => {
  axios.defaults.headers.common[name] = value;
};

export const deleteAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
};

const checkAppVersion = (response) => {
  if (response && response.data && response.data.appVersion) {
    //get app version from api
    /*if (appHasNewVersion(response.data.appVersion)) {
       Store.dispatch(
         saveAppVersion({
           version: response.data.appVersion,
         }),
       );
     }*/
  }

  return response;
};

const checkSessionEnded = (response) => {
  if (response && response.data && response.data.err_code === 402) {
    //can dispatch to delete localStorage UserInfo here
  }

  return response;
};

const checkSystemMessage = (response) => {
  const hasSystemMessageFromAPI = response && response.data && response.data.success && response.data.hasSystemMessage;
  //part below used to show data message if it changed
  /*const storeState = Store.getState();
   const { appInfo } = storeState;
   const { systemMessage = null } = appInfo;
   const hasSystemMessageFromStore = !!systemMessage;

   if (hasSystemMessageFromStore !== hasSystemMessageFromAPI) {
     if (hasSystemMessageFromAPI) {
       //Store.dispatch(loadSystemMessage());
       //can dispatch to show message value in api response
     } else {
       //Store.dispatch(clearSystemMessage());
       //dispatch to clear current message
     }
   }*/

  return response;
};

axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${Cookies.get('access_token')}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(checkSessionEnded, (error) => Promise.reject(error));
axios.interceptors.response.use(checkAppVersion, (error) => Promise.reject(error));
axios.interceptors.response.use(checkSystemMessage, (error) => Promise.reject(error));

class Request {
  constructor() {
    //Init cancelToken. Note: Must create cancel token for each request
    this.cancelToken = axios.CancelToken;
    this.source = this.cancelToken.source();
  }

  get(url, params) {
    let { urlProcess, allParams } = common.getURL(url, params);

    let dataSubmit = {
      params: allParams,
    };

    if (params?.responseType == 'blob') {
      dataSubmit['responseType'] = 'blob';
    }

    return axios
      .get(urlProcess, dataSubmit)
      .then((response, e, f, g) => {
        const ret = response.data;

        return ret;
      })
      .catch((error) => {
        return {
          success: false
        }
      });
  }

  post(url, params, config, payloadType) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const formPost = payloadType === 'formData' ? common.createFrom(allParams) : allParams;

    return axios
      .post(urlProcess, formPost, config)
      .then((response) => {
        const ret = response.data;

        return ret;
      })
      .catch((error) => {
        return Object.assign({}, error.response.data, {
          error_code: error.response.status,
        });
      });
  }

  put(url, params, config) {
    const { urlProcess, allParams } = common.getURL(url, params);
    const formPost = common.createFrom(allParams);

    return axios
      .put(urlProcess, formPost, config)
      .then((response) => {
        const ret = response.data;

        return ret;
      })
      .catch((error) => {});
  }

  delete(url, params, config = {}) {
    const { urlProcess, allParams } = common.getURL(url, params);

    return axios
      .delete(urlProcess, { params: allParams, ...config })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {});
  }

  //Cancel request by token request
  actionCancel() {
    this.source.cancel('Operation canceled by the user.');
  }
}

export default new Request();
