import { t1 } from "translate";
import { CURRENCY_OPTIONS } from "constants";
import { composeValidators, required, minValue } from "validations/index";
import { formatPrice } from "helper/helper";

const createGoldItemMarketSchema = (accountServer, min = 0) => ({
  amount: {
    type: "number",
    options: CURRENCY_OPTIONS,
    min,
    step: 1,
    max: accountServer?.balance / 1000000,
    label: t1("amount_of_gold_from_server_that_you_want_to_sell"),
    style: { width: "100%" },
    handleInputChange: (value, input) => {
      console.log({value});
      input.onChange(value)
    },
    validate: composeValidators(
      (value) => (required(value), minValue(value, min))
    ),
    errorText: {
      required: t1("%s_is_required", ["gold"]),
      invalidValue: t1("%s_min_is_%s", ["gold", min]),
    },
  },
  preViewAmount: {
    type: "text",
    label: t1("your_gold_sell"),
    disabled: true,
    modifySchema: (values, fieldData) => {
      let output = { ...fieldData };
      if (Number(values?.amount)) {
        output.forceAutoFill = formatPrice(Number(values?.amount) * 1000000);
      }

      return output;
    },
    style: {
      'backgroundColor': '#ffffff',
      'color': '#000000E0',
      'fontWeight': 'bold',
      'border': 'none',
      'cursor': 'default',
    }
  },
  price: {
    type: "number",
    min: 0.5,
    step: 0.5,
    label: t1("point_number"),
    style: { width: "100%" },
    handleInputChange: (e) => console.log(e),
    validate: composeValidators(
      (value) => (required(value), minValue(value, 0.5))
    ),
    errorText: {
      required: t1("%s_is_required", ["point"]),
      invalidValue: t1("%s_min_is_%s", ["point", 0.5]),
    },
  },
  preViewPriceEachUnit: {
    type: "text",
    label: t1("your_pre_order_price_per_mil"),
    disabled: true,
    modifySchema: (values, fieldData) => {
      let output = { ...fieldData };
      if (Number(values?.amount) && Number(values?.price)) {
        output.forceAutoFill = Math.round(Number(values?.price) * 10000 / Number(values?.amount)) / 10000;
      }
      return output;
    },
    style: {
      'backgroundColor': '#ffffff',
      'color': '#000000E0',
      'fontWeight': 'bold',
      'border': 'none',
      'cursor': 'default',
    }
  },
});

export default createGoldItemMarketSchema;
