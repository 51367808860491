import React, { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Table from "antd/lib/table";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import notification from 'antd/lib/notification';
import Pagination from "antd/lib/pagination";
import Tabs from "antd/lib/tabs";
import FormCreator from "commons/final-form/core/FormCreator";
import schema from "./schema-form/schema";
import ui from "./schema-form/ui";
import layout from "./schema-form/layout";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import Typography from "antd/lib/typography";

const { Title, Paragraph, Text, Link } = Typography;

//This will list all the item that are selling on the market, allow user to make a deal here
const QuestInfoLayout = ({profile}) => {
	const location = useLocation();

    const [questList, setQuestList] = useState([])
    const [dataQuest, setEndpointQuest, setParamsQuest] = useFetch(
		endpoints.quest_list,
		{
            level_start: 80,
            level_end: 100
        },
		"GET",
		(result, params) => {
            if (result?.data){
                setQuestList(result?.data?.quest.sort((a,b) => (parseInt(a.level) > parseInt(b.level)) ? 1 : -1));
            }
		},
		(result, params) => {
            console.log({fail: result})
		}
	);

    const onSubmit = (dataValue) => {
        setParamsQuest('');
        setParamsQuest(dataValue);
    }

	return (
		<PageLayout breadcrumList={[{ title: t1("dashboard"), link: '/dashboard' },{ title: t1("quest_info_tool"), link: location.pathname }]}>
            <Row gutter={[8, 8]}>
                <Col span={24} className="mt-2">
                    <Card title={null} style={{ borderRadius: "5px"}}>
                        <Row gutter={[16, 32]}>
                            <Col span={24}>
                                <FormCreator initialValues={{
                                    level_start: 80,
                                    level_end: 100
                                }} schema={schema} ui={ui()} layout={layout} onSubmit={onSubmit} SubmitButton={
                                    <button className="btn btn-primary" type="submit">
                                        {t1('search')}
                                    </button>
                                }/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title={null}>
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Tabs 
                                    tabPosition={'left'}
                                    style={{ height: '70vh' }}
                                    items={questList.map((quest, i) => {
                                        const id = quest['id'];
                                        return {
                                            label: <p style={{marginBottom: 0}}><b>{t1('lv %s',[quest['level']])}</b><span className="ms-1">{quest?.quest_title?.vietnamese ?? quest?.code}</span></p>,
                                            key: id,
                                            children: <Row>
                                                <Col span={24}>
                                                    <p className="fs-5">{quest?.quest_title?.vietnamese ?? quest?.code}</p>
                                                </Col>
                                                <Col span={24}>
                                                    <div dangerouslySetInnerHTML={{__html: quest?.content_code?.vietnamese}}></div>
                                                </Col>
                                            </Row>,
                                        };
                                    })}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
		</PageLayout>
	);
}

const mapStateToProps = (state,props) => {
	return {
		profile: state?.profile
	}
}

export default connect(mapStateToProps)(QuestInfoLayout);
