import React from "react";
import { t1 } from "translate";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Button from "antd/lib/button";
import { EXCHANGE_RATE } from "constants";
import { formatPrice } from "helper/helper";

const RefundPointReceipt = (props) => {
    const { pointAmount, bankAccount,onCreateRefundRequest } = props;

    return (
        <div
            style={{
                padding: "10px 20px",
                background: "#f9f9f9",
                border: "2px double rgb(148, 135, 127)",
                borderRadius: "8px",
            }}
        >
            <h5 className="fw-bold mb-4">{t1("your_receipt")}</h5>
            <Row>
                <Col span={24}>
                    <div className="d-flex justify-content-between mb-2">
                        <span style={{ fontWeight: "600", fontSize: "15px", color: "#aaaaaa" }}>
                            {t1("payment_method")}
                        </span>
                        <span>{t1("bank_transfer")}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <span style={{ fontWeight: "600", fontSize: "15px", color: "#aaaaaa" }}>
                            {t1("your_refund_point")}
                        </span>
                        <div>
                            {t1("%s", [formatPrice(pointAmount)])}
                            <img src="/cashicon.png" alt="sro money point icon" style={{ width:"20px", marginLeft: '3px'}}/>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span style={{ fontWeight: "600", fontSize: "15px", color: "#aaaaaa" }}>
                            {t1("exchange_rate")}
                        </span>
                        <span>{t1("%s_vnd", [formatPrice(EXCHANGE_RATE)])}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span style={{ fontWeight: "600", fontSize: "15px", color: "#aaaaaa" }}>
                            {t1("account_withdraw_to_bank")}
                        </span>
                        <span>{bankAccount?.bank_name}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span style={{ fontWeight: "600", fontSize: "15px", color: "#aaaaaa" }}>
                            {t1("account_number")}
                        </span>
                        <span>{bankAccount?.bank_account}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                        <span style={{ fontWeight: "600", fontSize: "15px", color: "#aaaaaa" }}>
                            {t1("account_name")}
                        </span>
                        <span>{bankAccount?.name}</span>
                    </div>
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={24}>
                    <h5 style={{ color: "#aaaaaa" }}>{t1("total")}</h5>
                </Col>
                <Col span={24}>
                    <span className="fw-bold" style={{ fontSize: "18px" }}>
                        {t1("%s_vnd", [formatPrice(pointAmount * EXCHANGE_RATE)])}
                    </span>
                </Col>
                <Col span={24}>
                    <Button
                        disabled={pointAmount === 0}
                        className="fw-bolder"
                        type="primary"
                        style={{ marginTop: "20px", width: "100%" }}
                        onClick={(e) => onCreateRefundRequest(pointAmount, bankAccount)}
                    >
                        {t1("hoàn_%s_", [formatPrice(pointAmount)])}
                        <img src="/cashicon.png" alt="sro money point icon" style={{ width:"24px", marginLeft: '4px'}}/>
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default RefundPointReceipt;
