import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { getCharacterImageFromModelId } from "commons/game";
import { t1 } from "translate";
import { CloseOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";

const ListGameCharacter = ({
    onRemove,
    listCharacter,
    onSelect,
    currentSelected,
    serverName,
}) => {
    return (
        <Row gutter={[16,16]}>
            {listCharacter.map((gameCharacter) => {
                return (
                    <Col
                        onClick={(e) =>
                            onSelect(gameCharacter.id)
                        }
                        span={24}
                        md={8}
                        lg={8}
                    >
                        <Row style={{
                            border: "3px double #333",
                            cursor: "pointer",
                        }}>
                            <Col span={8} className="p-2">
                                <img
                                    src={getCharacterImageFromModelId(
                                        gameCharacter.char_id
                                    )}
                                    alt={gameCharacter.name}
                                    style={{
                                        width: "100%",
                                        borderRadius: "5px",
                                    }}
                                />
                            </Col>
                            <Col className="p-2" span={12}>
                                <h5
                                    className="mb-1"
                                    style={{
                                        color: "#e6af14",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {gameCharacter.name}
                                </h5>
                                <p className="mb-0">
                                    {t1("job_name:_%s", [
                                        gameCharacter?.job_name,
                                    ])}
                                </p>
                                <p className="mb-0">
                                    {t1("server:_%s", [
                                        serverName,
                                    ])}
                                </p>
                            </Col>
                            <Col className="p-2" span={4}>
                                <Button
                                    onClick={(e) =>
                                        onRemove(
                                            gameCharacter.id
                                        )
                                    }
                                    type="primary"
                                    icon={<CloseOutlined />}
                                    size={"large"}
                                    danger
                                />
                            </Col>
                        </Row>
                    </Col>
                );
            })}
        </Row>
    );
};

export default ListGameCharacter;
