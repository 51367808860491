import React from "react";
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { MAPPING_CODE_WITH_IMAGE_MONSTER, MAPPING_SKILL_TYPE } from '../constant/mapping';
import { t1 } from "translate";

const getImageMonster = (monsterCode) => {
    if (MAPPING_CODE_WITH_IMAGE_MONSTER[monsterCode]){
        return 'https://sro.money/images/mob/' + MAPPING_CODE_WITH_IMAGE_MONSTER[monsterCode];
    }else{
        return 'https://static.vecteezy.com/system/resources/previews/015/568/001/original/question-mark-red-hand-drawn-doodle-faq-symbol-free-vector.jpg';
    }
}

const getSkillType = (skillDetail = []) => {
    let output = t1('unknow_skill_type');

    let outputSkillDetail = skillDetail.find((item) => item.detail_code == 6386804);

    if (outputSkillDetail && MAPPING_SKILL_TYPE[outputSkillDetail['detail_array'][0]]){
        output = MAPPING_SKILL_TYPE[outputSkillDetail['detail_array'][0]]
    }

    return output;
}

const getSkillDam = (skillDetail = []) => {
    let output = {
        min: 0,
        max: 0,
        percentage: 100
    };

    let outputSkillDetail = skillDetail.find((item) => item.detail_code == 6386804);

    if (outputSkillDetail){
        output.percentage = outputSkillDetail['detail_array'][1];
        output.min = outputSkillDetail['detail_array'][2];
        output.max = outputSkillDetail['detail_array'][3];
    }

    return `${output.min} ~ ${output.max} (${output.percentage}%)`;
}

const getSkillHit = (skillDetail = []) => {
    let output = 1;
    let outputSkillDetail = skillDetail.find((item) => item.detail_code == 28003);

    if (outputSkillDetail){
        output = outputSkillDetail['detail_array'][1];
    }

    return output;
}

const getSkillAoe = (skillDetail = []) => {
    let output = {
        is_aoe: false,
        aoe_range: 10,
        atk_target: 1
    }

    let outputSkillDetail = skillDetail.find((item) => item.detail_code == 6645362);

    if (outputSkillDetail){
        output.is_aoe = true;
        output.aoe_range = outputSkillDetail['detail_array'][2];
        output.atk_target = outputSkillDetail['detail_array'][3];
    }

    if (output.is_aoe){
        return t1('aoe_skill_affect_%s_target_in_%d_area',[output.atk_target,output.aoe_range / 10])
    }else{
        return t1('this_is_not_aoe_skill')
    }
}

const renderSkillInfo = (skillList = []) => {
    return <div className="row">
        {skillList.map((skillData, indexSkill) => {
            return <div className="col-6" style={{border: '1px dashed #333', borderRadius: '5px'}}>
                <p className="mt-1 mb-0">{t1('skill_no_%s',[indexSkill + 1])}</p>
                <p className="mb-0">{t1('skill_type:_%s',[getSkillType(skillData?.skill_detail)])}</p>
                <p className="mb-0">{t1('skill_dam:_%s',[getSkillDam(skillData?.skill_detail)])}</p>
                <p className="mb-0">{t1('skill_channeling_time:_%s',[skillData?.chanelling / 1000])}</p>
                <p className="mb-0">{t1('skill_apply_time:_%s',[skillData?.real_cast_time / 1000])}</p>
                <p className="mb-0">{t1('skill_cooldown:_%s',[skillData?.skill_cooldown / 1000])}</p>
                <p className="mb-0">{t1('skill_hit:_%s',[getSkillHit(skillData?.skill_detail)])}</p>
                <p>{t1('skill_aoe:_%s',[getSkillAoe(skillData?.skill_detail)])}</p>
            </div>
        })}
    </div>
}

const MonsterInfo = ({monster}) => {
    return <div className="row">
        <div className="col-12">
            <p className="fs-5">{monster?.object_name?.vietnamese ?? monster?.code}</p>
        </div>
        <div className="col-3">
            <img src={getImageMonster(monster?.code)} alt={monster?.object_name?.vietnamese ?? monster?.code} style={{maxWidth: '200px'}}/>
        </div>
        <div className="col-9">
            <p className="fw-bold mb-0">{t1('monster_hp')}: {monster.hp}</p>
            <p className="fw-bold mb-0">{t1('monster_phys_def')}: {monster.def_phys}</p>
            <p className="fw-bold mb-0">{t1('monster_mag_def')}: {monster.def_mag}</p>
            <p className="fw-bold mb-0">{t1('monster_def_ratio')}: {monster.def_ratio}</p>
            <p className="fw-bold mb-0">{t1('monster_hit_ratio')}: {monster.hit_ratio}</p>
            <p className="fw-bold mb-0">{t1('monster_speed')}: {monster.object_speed}</p>
            <p className="fw-bold mb-0">{t1('exp_normal_monster')}: {monster.exp}</p>
            <p className="fw-bold mb-0">{t1('exp_over_hp_ratio')}: {Math.floor(monster.exp*1000/monster.hp)/1000}</p>
            <p className="fw-bold mb-0">{t1('auto_atk')}: {monster.auto_atk != 1 ? t1('auto_attack') : t1('not_auto_attack')}</p>
            <p>{t1('attack_skill:_%s',[monster?.skill_list?.length])}</p>
        </div>
        <div className="col-12">
            {renderSkillInfo(monster?.skill_list)}
        </div>
    </div>
}

export default MonsterInfo;