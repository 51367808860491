import "App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/store";
import routeSchema from "pages/routes";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { ConfigProvider, App } from "antd";
import "scss/style.scss";
import Header from 'components/AuthWrapper/header';
import MobileDetect from 'mobile-detect'

//this function use to log all redux state out
window.logState = () => {
    const state = store.getState();
    console.log({ state });
};

const Application = (props) => {
    var md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile()) {
        const urlPathName = window.location.pathname;
        const urlSearch = window.location.search;
        window.location.replace(window.MOBILE_SERVER + urlPathName + urlSearch);
    }

    return (
        <ConfigProvider>
            <App>
                <Provider store={store}>
                    <Router>
                        <Header />
                        <Routes>
                            {routeSchema.map((route, index) => (
                                <Route key={index} path={route.path} element={route.component} exact={route.exact} />
                            ))}
                        </Routes>
                    </Router>
                </Provider>
            </App>
        </ConfigProvider>
    );
};

export default Application;
