import React, { useState } from "react";
import { t1 } from "translate";
import Modal from "antd/lib/modal";
import { SERVER_LIST } from "constants/game";
import { endpoints } from "constants/endpoints";
import DataFetchHook from "commons/hook/fetchDataHook";
import FormCreator from "commons/final-form/core/FormCreator";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import searchGameCharacterUI from "schema-form/ui/searchGameCharacterUI";
import searchGameCharacterSchema from "schema-form/schema/searchGameCharacterSchema";
import searchGameCharacterLayout from "schema-form/layout/searchGameCharacterLayout";
import Button from "antd/lib/button";
import Table from "antd/lib/table";
import { getCharacterImageFromModelId } from "commons/game";
import Message from "antd/lib/message";
import otpCharacterSchema from "schema-form/schema/otpCharacterSchema";
import otpCharacterUI from "schema-form/ui/otpCharacterUI";
import otpCharacterLayout from "schema-form/layout/otpCharacterLayout";

const columnList = (onClickConnect) => [
    {
        title: t1("model_avatar"),
        dataIndex: "char_id",
        key: "char_id",
        render: (data, record, index) => {
            return (
                <img
                    style={{ width: "60px" }}
                    src={getCharacterImageFromModelId(data)}
                    alt={record?.name}
                />
            );
        },
    },
    {
        title: t1("character_name"),
        dataIndex: "name",
        key: "name",
    },
    {
        title: "action",
        dataIndex: "id",
        key: "id",
        render: (data, record, index) => {
            return (
                <Button
                    onClick={(e) => onClickConnect(record)}
                >
                    {t1("connect")}
                </Button>
            );
        },
    },
];

const ModalConnectCharacter = ({
    visible,
    setVisible,
    serverId,
    refreshSearch,
}) => {
    let serverInfo = SERVER_LIST.find(
        (e) => e.id == serverId
    );
    const [listCharacter, setListCharacter] = useState([]);
    const [mode, setMode] = useState(0);
    const [currentCharacter, setCurrentCharacter] =
        useState(false);

    const [
        searchGameCharacter,
        setEndpointSearchGameCharacter,
        setParamsSearchGameCharacter,
    ] = DataFetchHook(
        endpoints.search_character,
        "",
        "GET",
        (result, params) => {
            let listCharacterToFilter = result?.data?.items || [];
            listCharacterToFilter =
                listCharacterToFilter.filter(
                    (character) =>
                        !character.user_id &&
                        !character.profile_id
                );
            setListCharacter(listCharacterToFilter);
        },
        (result, params) => {
            console.log({ result });
        }
    );

    const [
        linkAccountData,
        setLinkAccountUrl,
        setLinkAccountParams,
    ] = DataFetchHook(
        endpoints.link_account,
        "",
        "POST",
        (result, params) => {
            if (result?.success) {
                setMode(1);
            }
        },
        (result, params, errMessage) => {
            Message.error(
                t1(errMessage || '') || t1("connect_account_failed")
            );
        }
    );

    const onClickConnect = (character) => {
        setCurrentCharacter(character);
        setLinkAccountParams("");
        setLinkAccountParams({
            server_id: character?.server_id,
            game_id: character?.game_id,
            id: character?.id,
        });
    };

    const [verifyData, setVerifyUrl, setVerifyParams] =
        DataFetchHook(
            endpoints.confirm_connect_account,
            "",
            "POST",
            (result, params, message) => {
                if (result?.data) {
                    Message.success(
                        message ||
                            t1("connect_account_success")
                    );
                    refreshSearch(1);
                    setVisible(false);
                }
            },
            (result, params, errMessage) => {
                Message.error(
                    t1(errMessage || '') ||
                        t1("verify_account_failed")
                );
            }
        );

    return (
        <Modal
            title={t1("connect_game_character_to_account_server") + t1('_%s', [serverInfo?.name])}
            open={visible}
            footer={null}
            onCancel={(e) => setVisible(false)}
        >
            {mode == 1 ? (
                <Row>
                    <Col span={24}>
                        <FormCreator
                            schema={otpCharacterSchema}
                            ui={otpCharacterUI}
                            layout={otpCharacterLayout}
                            SubmitButton={(form) => (
                                <Button
                                    type="primary"
                                    onClick={(e) => {
                                        form.submit();
                                    }}
                                >
                                    {t1("submit_otp")}
                                </Button>
                            )}
                            onSubmit={(data) => {
                                setVerifyParams("");
                                setVerifyParams({
                                    ...data,
                                    game_id: 1,
                                    server_id: serverId,
                                    id: currentCharacter?.id,
                                });
                            }}
                        />
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col span={24}>
                        <FormCreator
                            schema={
                                searchGameCharacterSchema
                            }
                            ui={searchGameCharacterUI}
                            layout={
                                searchGameCharacterLayout
                            }
                            SubmitButton={(form) => (
                                <Button
                                    type="primary"
                                    onClick={(e) => {
                                        form.submit();
                                    }}
                                >
                                    {t1("search")}
                                </Button>
                            )}
                            onSubmit={(data) => {
                                setParamsSearchGameCharacter(
                                    ""
                                );
                                setParamsSearchGameCharacter(
                                    {
                                        ...data,
                                        game_id: 1,
                                        server_id: serverId,
                                    }
                                );
                            }}
                        />
                    </Col>
                    <Col span={24} className="mt-2">
                        <Table
                            dataSource={listCharacter}
                            columns={columnList(
                                onClickConnect
                            )}
                        />
                    </Col>
                </Row>
            )}
        </Modal>
    );
};

export default ModalConnectCharacter;
