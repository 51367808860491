import { t1 } from 'translate';
import { required } from 'commons/final-form/utils';
import { composeValidators, emailValidator } from 'commons/final-form/utils';

const registerSchema = {
    user_name: {
        type: 'text',
        label: t1('user_name'),
        validate: required,
        errorText: {
            required: t1('this_field_is_require'),
        },
    },
    email: {
        type: 'text',
        label: t1('email'),
        validate: composeValidators(required, emailValidator),
        errorText: {
            required: t1('this_field_is_require'),
            emailInvalid: t1('email_is_not_valid'),
        },
    },
    password: {
        type: 'password',
        label: t1('password'),
        validate: required,
        errorText: {
            required: t1('this_field_is_require'),
        },
    },
    confirm_password: {
        type: 'password',
        label: t1('confirm_password'),
        // validate: required,
        // errorText: {
        //     required: t1('this_field_is_require'),
        // },
        modifySchema: (values, fieldData) => {
            let output = { ...fieldData };
            if (!!values?.password && !!values?.confirm_password) {
                if (values?.password === values?.confirm_password) {
                    output = {
                        ...fieldData,
                        validate: required,
                        errorText: {
                            ...fieldData?.errorText,
                            required: '',
                        },
                        key: 'same'
                    }
                } else {
                    output = {
                        ...output,
                        validate: composeValidators(() => values?.password === values?.confirm_password ? undefined : 'x'),
                        errorText: {
                            ...fieldData?.errorText,
                            x: '123',
                        },
                        key: "not_same",
                    }
                }
            } else {
                output = {
                    ...output,
                    validate: composeValidators(required),
                    errorText: {
                        ...fieldData?.errorText,
                        required: t1('this_field_is_require'),
                    },
                    key: "cf_pw",
                }
            }
            return output;
        },
    },
    agree_tos: {
        type: 'checkbox',
        hideLabel: true,
        label: t1('agree_to_our_terms_of_service'),
        mode: 'single',
        option: {
            label: t1('agree_to_our_terms_of_service'),//will need to be open modal global here
        },
        validate: required,
        errorText: {
            required: t1('this_field_is_require'),
        }
    }
}

export default registerSchema