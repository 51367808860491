import React from "react";
import ListGameCharacter from "./ListGameCharacter";
import { t1 } from "translate";
import Button from 'antd/lib/button';

const CharacterListTabContent = ({
    serverInfo,
    onRemoveCharacter,
    onSelectCharacter,
    currentSelected,
    isAbleToAddCharacter = true,
    onClickAddCharacter,
}) => {
    return (
        <div
            className="p-3"
            style={{
                backgroundColor: "#fff",
                boxShadow:
                    "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                border: "7px double #94877f",
            }}
        >
            <h5 className="text-center mb-5">
                {t1("game_character_connected_in_server") +  t1('_%s', [serverInfo?.name])}
            </h5>
            <ListGameCharacter
                onRemove={onRemoveCharacter}
                onSelect={onSelectCharacter}
                currentSelected={currentSelected}
                listCharacter={serverInfo?.list_character}
                serverName={serverInfo?.name}
            />

            <p className="text-center">
                <Button className="mt-4" onClick={onClickAddCharacter} disabled={!isAbleToAddCharacter}>
                    {t1("connect_new_game_character")}
                </Button>
            </p>
        </div>
    );
};

export default CharacterListTabContent;
