import React, { useState } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import DataFetchHook from 'commons/hook/fetchDataHook';
import { endpoints } from 'constants/endpoints';
import Table from 'antd/lib/table';
import { SERVER_LIST } from 'constants/game';
import { t1 } from 'translate';
import Tag from 'antd/lib/tag';
import Progress from 'antd/lib/progress';

const BotInfo = () => {
    const [ totalBotSlot, setTotalBotSlot ] = useState(0);
    const [ botList, setBotList ] = useState([]);

    const [data,updateEndpointBot,updateParamsBot] = DataFetchHook(endpoints.bot_list,{submit: 1, limit: 99999}, 'GET', (result,params) => {
        setBotList(result?.data);
    })

    const [dataSlot,updateEndpointSlot,updateParamsSlot] = DataFetchHook(endpoints.number_bot_free,{submit: 1, limit: 99999}, 'GET', (result,params) => {
        setTotalBotSlot(result?.data?.free);
    })

    const getServer = (serverId) =>
        SERVER_LIST.find(
            (server) => server.id === Number(serverId)
        )?.name || "";

        const columns = [
            {
              title: t1('bot_name'),
              dataIndex: 'char_name',
              key: 'char_name',
              render: (text) => <a>{text}</a>,
            },
            {
              title: t1('server'),
              dataIndex: 'server_id',
              key: 'server_id',
              render: (data) => {
                return getServer(data)
              }
            },
            {
              title: t1('active_status'),
              dataIndex: 'active_status',
              key: 'active_status',
              render: (status) => (
                <Tag color={status ? 'green' : 'volcano'}>
                  {status ? 'Active' : 'Inactive'}
                </Tag>
              ),
            },
            {
              title: t1('online_status'),
              dataIndex: 'online_status',
              key: 'online_status',
              render: (status) => (
                <Tag color={status ? 'green' : 'volcano'}>
                  {status ? 'Online' : 'Offline'}
                </Tag>
              ),
            },{
              title: t1('storage'),
              dataIndex: 'character_total_storage_slot',
              key: 'character_total_storage_slot',
              render: (data,record,index) => {
                return <Progress steps={25} percent={Math.floor((record?.character_total_storage_slot - record?.empty_storage_number) * 100/record?.character_total_storage_slot)} size={[5,10]}/>
              },
            }
          ];

    return <Row>
        <Col span={24}>
            <p>Hiện tại server có thể thực hiện {t1('_%s_',[totalBotSlot])} giao dịch cùng lúc</p>
        </Col>
        <Col span={24}>
            <Table
                style={{ width: '100%' }}
                columns={columns}
                dataSource={botList}
                pagination={{
                    pageSize: 5
                }}
            />
        </Col>
    </Row>
}

export default BotInfo