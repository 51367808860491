import {
  EMAIL_REGEX,
  PHONE_REGEX,
  URL_REGEX
} from 'constants/validate';
import dayjs from 'dayjs';
import message from 'antd/lib/message';
import { get } from 'lodash';
import { cloneDeep } from 'lodash';

export const required = (value) => {
  let error = value;

  if (typeof error === 'string') {
    error = error?.trim();
  } else if (Array.isArray(error) && !error.length) {
    return 'required';
  }
  return error || error == 0 ? undefined : 'required';
};

export const requiredError = () => {
  return 'required';
};

export const requiredAllOption = (value, args) => {
  return value && value.length === args.countOption ? undefined : 'required';
};

export const requiredNotFullSpace = (value) => {
  return value.trim() === '' ? 'required' : undefined;
};

export const mustBeNumber = (value) => (isNaN(value) ? 'must_be_a_number' : undefined);
export const minValue = (min) => (value) => isNaN(value) || value >= min ? undefined : `min_value_${min}`;
export const maxValue = (max) => (value) => isNaN(value) || value <= max ? undefined : `max_value_${max}`;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const numberMustBePositive = (value) => (value <= 0 ? 'negative_number' : undefined);

export const checkFileSize = (file, limitSize) => (file?.size > limitSize ? 'file_size_exceed' : undefined);

export const validateMinLength = (value, args) => {
  if (value && value.length < args.minLength) {
    return 'min_length';
  }
  return undefined;
};

export const validateMaxLength = (value, args) => {
  if (value && value.length > args.maxLength) {
    return 'max_length';
  }
  return undefined;
};

export const validateOptions = (value, args) => {
  if (value && value.length === args.minOption) {
    return 'min_option';
  }
  return undefined;
};

export const notSpaceBeginEnd = (value, args) => {
  if ((value && String(value).startsWith(' ')) || String(value).endsWith(' ')) {
    return 'not_space_begin_end';
  }
  return undefined;
};

export const validateDatePast = (value) => {
  return value && !(dayjs().isSameOrBefore(dayjs(value)) || dayjs(value).isToday()) ? undefined : 'invalid_date';
};

export const validateDateFuture = (value) => {
  return value && !dayjs().isBefore(dayjs(value)) ? undefined : 'invalid_date';
};
export const noneRequire = () => {
  return undefined;
};

export const invalidStartDateEndDate = (value) => {
  return value ? 'invalid_start_end_date' : undefined;
};

export const mustBeEmail = (value) => {
  return value && !new RegExp(EMAIL_REGEX).test(value) ? '入力形式は正しくありません。' : undefined;
};

export const mustArrayBeEmail = (value) => {
  const listEmail = value
    ?.split(';')
    .map((item) => item?.trim())
    .map((item) => {
      return !new RegExp(EMAIL_REGEX).test(item) ? '入力形式は正しくありません。' : undefined;
    });
  return value && listEmail;
};

export const validatePercent = (value) => {
  return value && value > 100 ? '100%以内に入力してください。' : undefined;
};

export const mustBeFuture = (value) => {
  return value && (!dayjs().isAfter(dayjs(value)) || dayjs(value).isToday()) ? undefined : 'invalid_date';
};

export const mustBeTimeFuture = (value) => {
  return value && !dayjs().isAfter(value) ? undefined : 'invalid_time';
};

export const needToBeChange = (value) => {
  return 'need_to_be_change';
};

export const modifySchemaToReviewField = (schema) => {
  let outputSchema = {};

  Object.keys(schema).map((fieldName) => {
    if (fieldName == 'full_name') {
      outputSchema['usercode'] = {
        ...schema[fieldName],
        type: 'review_data',
        old_type: schema[fieldName]['old_type'] ? schema[fieldName]['old_type'] : schema[fieldName]['type'],
      };
    } else {
      if (schema[fieldName]['type'] == 'array' || schema[fieldName]['type'] == 'array_review_data') {
        outputSchema[fieldName] = {
          ...schema[fieldName],
          type: 'array_review_data',
          old_type: schema[fieldName]['old_type'] ? schema[fieldName]['old_type'] : schema[fieldName]['type'],
        };
      } else {
        outputSchema[fieldName] = {
          ...schema[fieldName],
          type: 'review_data',
          old_type: schema[fieldName]['old_type'] ? schema[fieldName]['old_type'] : schema[fieldName]['type'],
        };
      }
    }
  });

  return outputSchema;
};

export const createUIforReviewField = (schema) => {
  return {
    default: {
      fields: Object.keys(schema),
    },
  };
};

export const modifySchemaArrayChildReviewField = (schema) => {
  let outputSchema = cloneDeep(schema);

  Object.keys(schema.children).map((fieldName) => {
    if (schema.children[fieldName]['type'] == 'array' || schema.children[fieldName]['type'] == 'array_review_data') {
      outputSchema.children[fieldName] = {
        ...schema.children[fieldName],
        type: 'array_review_data',
        old_type: schema.children[fieldName]['type'],
      };
    } else {
      outputSchema.children[fieldName] = {
        ...schema.children[fieldName],
        old_type: schema.children[fieldName]['old_type'] ? schema.children[fieldName]['old_type'] : schema.children[fieldName]['type'],
        type: 'review_data',
      };
    }
  });

  return outputSchema;
};

export const emailValidator = value => (
  String(value)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ? undefined
      : 'emailInvalid'
);
