import React from "react";
import { t1 } from "translate";
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Progress from 'antd/lib/progress';

const AccountLimit = (props) => {
    return (
        <div className={`mt-3 userinfo-block`}>
            <p className={`text-center big-text`}>
                {t1("account_limit")}
            </p>
            <Row>
                <Col span={16}>
                    <p>
                        {t1(
                            "total_connected_character:_%s",
                            ["1/3"]
                        )}
                    </p>
                    <p>
                        {t1("total_inventory_slot:_%s", [
                            "5/10",
                        ])}
                    </p>
                    <p>
                        {t1("total_market_sell:_%s", [
                            "58/100",
                        ])}
                    </p>
                </Col>
                <Col span={8}>
                    <p>
                        <Progress
                            percent={33}
                            size="small"
                        />
                    </p>
                    <p>
                        <Progress
                            percent={50}
                            size="small"
                        />
                    </p>
                    <p>
                        <Progress
                            percent={58}
                            size="small"
                        />
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default AccountLimit;
