import React from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { SERVER_LIST } from 'constants/game';
import Statistic from 'antd/lib/statistic';
import { t1 } from 'translate';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';

const GoldAccountDetail = ({profile, action = (e) => {
    console.log(e)
}, showCreateListingButton = false, filterServer = SERVER_LIST}) => {
    return <Row gutter={[16,16]}>
        {SERVER_LIST.map((serverInfo,indexServer) => {
            if (filterServer?.map((e) => e?.id)?.includes(serverInfo?.id)){
                return <Col span={8}>
                    <Statistic title={t1('gold_%s',[serverInfo?.name])} value={profile?.account?.gold_accounts[indexServer]?.balance} formatter={(value) => {
                        return <p><span className="me-1"><img src="/goldicon.png" alt="sro money gold icon" style={{width:"20px", verticalAlign: 'inherit'}}/></span>{value.toLocaleString()}</p>
                    }}/>
                    {showCreateListingButton ? <Button type="primary" shape="round" size="small" onClick={(e) => {
                        action(indexServer)
                    }}>{t1('sell_gold')}</Button>: <></>}                
                </Col>
            }else{
                return <></>
            }
        })}
    </Row>
}

const mapStateToProps = (state,props) => {
    return {
        profile: state?.profile
    }
}

export default connect(mapStateToProps)(GoldAccountDetail);