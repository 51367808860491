import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const DepthChart = ({optionsData}) => {
    return <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={optionsData}
        />
    </div>
}

export default DepthChart;