import React, { useState } from "react";
import { connect } from "react-redux";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import FormCreator from "commons/final-form/core/FormCreator";
import userInfoSchema from "schema-form/schema/userInfoViewSchema";
import userInfoUI from "schema-form/ui/userInfoUI";
import userInfoGeneralInfoViewLayout from "schema-form/layout/userInfoGeneralInfoViewLayout";
import UserInfoBlock from "components/UserInfo/UserInfoBlock";
import AccountLimit from "components/UserInfo/AccountLimit";
import {t1 } from 'translate';
import Button from 'antd/lib/button';
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import Message from "antd/lib/message";
import dayjs from "dayjs";


const UserInfoGeneralBlock = ({ profile, reloadProfile }) => {
    const [editGeneral, setEditGeneral] = useState(false);

    const [data, UpEnpoints, setUpdateParams] = useFetch(
        endpoints.user_update,
        "",
        "post",
        (result, params) => {
            Message.success("Update successful");
            setEditGeneral(false);
            reloadProfile();
        },
        (result, params) => {
            if (!result?.success) {
                Message.error(result?.message || "Update failed");
            }
        }
    );

    const onSubmitUpdate = (values) => {
        const updateParams = {
            dob: values?.dob ? dayjs(values?.dob).format("YYYY-MM-DD HH:mm:ss") : null,
            avatar: values?.avatar || null,
            phone_number: values?.phone_number,
            username: values?.username,
        };
        setUpdateParams(updateParams);
    };

    return (
        <Card title={null} style={{ borderRadius: "5px" }}>
            <Row gutter={[16, 24]}>
                <Col span={24} md={12}>
                    <FormCreator
                        debugMode={false}
                        initialValues={profile}
                        schema={userInfoSchema}
                        ui={userInfoUI}
                        uiId={"general_info"}
                        layout={userInfoGeneralInfoViewLayout}
                        mode={editGeneral ? "edit" : "view"}
                        SubmitButton={
                            editGeneral ? (
                                (form) => {
                                    return (
                                        <div>
                                            <Button
                                                className="me-2"
                                                onClick={(e) => {
                                                    form.reset();
                                                    setEditGeneral(false);
                                                }}
                                            >
                                                {t1("cancel")}
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={(e) => {
                                                    form.submit();
                                                    setEditGeneral(false);
                                                }}
                                            >
                                                {t1("save")}
                                            </Button>
                                        </div>
                                    );
                                }
                            ) : (
                                <></>
                            )
                        }
                        onSubmit={(e) => onSubmitUpdate(e)}
                    />
                </Col>
                <Col span={24} md={2}>
                    {editGeneral ? (
                        <></>
                    ) : (
                        <div>
                            <Button type="primary" onClick={(e) => setEditGeneral(true)}>
                                {t1("update_info")}
                            </Button>
                        </div>
                    )}
                </Col>
                {!editGeneral && (
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <UserInfoBlock />
                            </Col>
                            <Col span={12}>
                                <AccountLimit />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
        </Card>
    );
};

const mapStatesToProps = (state, props) => {
    return {
        profile: state?.profile?.profile || {},
        reloadProfile: state?.profile?.reloadProfile,
    };
};

export default connect(mapStatesToProps)(UserInfoGeneralBlock);
