import React, { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import useFetch from "commons/hook/fetchDataHook";
import SearchItem from './components/SearchItem';
import { endpoints } from "constants/endpoints";
import MarketListingBlock from "components/ItemDetail/MarketListingBlock";
import styles from './styles.module.scss';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Pagination from "antd/lib/pagination";
import { ITEM_GOLD_MODEL_ID } from "constants";
import MarketGoldListingBlock from "components/ItemDetail/MarketGoldListingBlock";
import { InputNumber, Input, Button } from "antd/lib";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { getQueryParams } from 'helper/helper';
import Divider from 'antd/lib/divider';
import DiscountImage from 'assets/icons/common/discount.svg';
import DiscountTagImage from 'assets/icons/common/discount-tag.svg';

//This will list all the item that are selling on the market, allow user to make a deal here
const MarketLayout = ({profile}) => {
	const location = useLocation();
  const { server = 3, model = '' } = getQueryParams(location.search, ['server', 'model']);

	const [itemList, setItemList] = useState([]);
    const [paramsFilter,setParamsFilter] = useState({
        server_id: 3
    });
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(12);
	const [currentItem, setCurrentItem] = useState({});
    const [showModal,setShowModal] = useState(false);
	const [showModalGold, setShowModalGold] = useState(false);
	const [goldBuy,setGoldBuy] = useState(0);
	const [couponCode, setCouponCode] = useState('');
	const [couponCodeErr, setCouponCodeErr] = useState('');

	const [dataInventory, setEndpointInventory, setParamsInventory] = useFetch(
		endpoints.search_item,
		{ submit: 1, page: page, limit: pageSize, ...paramsFilter},
		"GET",
		(result, params) => {
			setItemList(result?.data);
			setPage(result?.current_page);
			setTotal(result?.total);
			setPageSize(result?.per_page);
		},
		(result, params) => {
			setItemList(result?.data);
			setPage(result?.current_page);
			setTotal(result?.total);
			setPageSize(result?.per_page);
		}
	)

    const [data, setEndpoint, setParams] = useFetch(
		endpoints.purchase_item,
		'',
		"POST",
		(result, params) => {
			onClosePurchaseModal();
			setShowModalGold(false);
			setGoldBuy(0);
            setCurrentItem({});
            setParamsInventory('');
            setParamsInventory({
                submit: 1, page: page, limit: pageSize, ...paramsFilter
            });
			if (profile?.reloadProfile){
				profile?.reloadProfile();
			}
		},
		(result, params) => {
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
			onClosePurchaseModal();
		}
	)

	useEffect(() => {
		if (!!model) {
			const splitModel = model.split('-');
			const modelId = Number(splitModel[0]);
			if (modelId) {
				setParamsInventory('');
        setParamsInventory({
					server_id: server,
					model_id: modelId,
          submit: 1,
					page: page,
					limit: pageSize
        })
			}
		}
	}, [server, model]);

	const [dataCalculatePrice, setEndpointCalculatePrice, setParamsCalculatePrice] = useFetch(
		endpoints.calculate_price,
		'',
		"POST",
		(result, params) => {
			if (result?.success && result?.data?.sale_off) {
				notification.success({
					message: t1('success_message'),
					description: t1('apply_coupon_success')
				});
				const newItem = {
					...currentItem,
					sale_off: result?.data?.sale_off,
					price_after_sale: result?.data?.price,
				};
				setCurrentItem(newItem);
			} else {
				notification.error({
					message: t1('error_message'),
					description: t1('coupon_is_not_valid')
				});
			}
		},
		(result, params) => {
      notification.warning({
				message: t1('warning_message'),
				description: result?.message
			});
		}
	)

	const onApplyCoupon = (item, coupon) => {
		if (!coupon) return setCouponCodeErr('required');
		setParamsCalculatePrice({});
		setParamsCalculatePrice({
			item_id: item?.item_id,
			coupon_code: coupon,
			quantity: 1,
			open_price_id: item?.item?.latest_open_price_id
		});
	}

	const onClosePurchaseModal = () => {
		setShowModal(false);
		setCouponCode('');
	};

	return (
		<PageLayout breadcrumList={[{ title: t1("market_page"), link: '/market' }]}>
			<Col span={24}>
				<Card title={t1('market_layout')} style={{ borderRadius: "5px"}}>
					<Row gutter={[16, 32]}>
						<Col span={24} className={styles.filter}>
							<SearchItem
								styles={styles}
								params={paramsFilter}
								updateParams={(valueSet) => {
                                    setParamsFilter(valueSet);
                                    setParamsInventory('');
                                    setParamsInventory({
                                        submit: 1, page: page, limit: pageSize, ...valueSet
                                    })
                                }}
							/>
						</Col>
						<Col span={24} className={styles.itemList}>
							<Row gutter={[16,8]}>
								{itemList?.map((listingInfo) => {
									return <Col span={6} >
										<div style={{
										backgroundColor: "#fff",
										boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
										borderRadius: '4px',
										minHeight: '100px',
									}}>
										{listingInfo?.model_id == ITEM_GOLD_MODEL_ID ? <MarketGoldListingBlock listingInfo={listingInfo} action={(infoListing) => {
                                            setCurrentItem(infoListing);
                                            setShowModalGold(true);
                                        }}/> : <MarketListingBlock listingInfo={listingInfo} action={(infoListing) => {
                                            setCurrentItem(infoListing);
                                            setShowModal(true);
                                        }}/>}
										</div>
									</Col>
								})}
								<Col span={24} style={{ textAlign: 'right' }}>
									<Pagination defaultCurrent={page} total={total} pageSize={pageSize} onChange={(pageNumber,pageSizeNumber) => {
										if (pageSizeNumber != pageSize){
											setParamsInventory('');
											setParamsInventory({ submit: 1, page: 1, limit: pageSizeNumber, ...paramsFilter})
										}else{
											setParamsInventory('');
											setParamsInventory({ submit: 1, page: pageNumber, limit: pageSizeNumber, ...paramsFilter})
										}
									}}/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card>
			</Col>
      <Modal
				title={t1('confirm_purchase')}
				open={showModal}
				onOk={(e) => {
					const tradeParams = {
						item_id: currentItem?.item?.id,
            open_price_id: currentItem?.id,
            quantity: 1,
					}
					if (!!couponCode) tradeParams.coupon_code = couponCode;
          setParams('');
          setParams(tradeParams)
      	}}
				onCancel={onClosePurchaseModal}
			>
				<div className="d-flex justify-content-between">
        	<p>{t1('click_ok_to_purchase_item_to_your_inventory')}</p>
				</div>
        <MarketListingBlock listingInfo={currentItem}/>
				<Divider style={{ margin: '10px 0', background: '#dbdbdb' }} />
				<div className={styles.couponContainer}>
					<div className={styles.couponTitle}>
						<img src={DiscountImage} alt="discount-img" />
						<span style={{ marginLeft: '5px' }}>{t1('coupon')}</span>
					</div>
					<div className={styles.couponDetail}>
						{currentItem?.sale_off ? (
							<div className={styles.couponCard}>
								<div className={styles.couponCardContainer}>
									<div className={styles.couponTag}>
										<img src={DiscountTagImage} alt="discount tag"/>
									</div>
									<div className={styles.couponCardDetail}>
										<div className={styles.couponName}>{t1('coupon_:_%s', [couponCode])}</div>
										<div className={styles.couponDiscount}>
											<span>{t1('discount_%s', [currentItem.sale_off])}</span>
											{currentItem?.concurrency === 0
												? <img src="/cashicon.png" alt="sro money point icon" style={{width:"18px", marginLeft: '3px'}}/>
												: <img src="/goldicon.png" alt="sro money gold icon" style={{width:"14px", marginLeft: '3px'}}/>
											}
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className="position-relative d-flex">
								<Input
									placeholder={t1('type_coupon_discount')}
									value={couponCode}
									onChange={(e) => setCouponCode(e.target.value)}
								/>
								{!!couponCodeErr && (
									<div className={styles.couponErr}>{couponCodeErr}</div>
								)}
								<Button
									type="primary"
									disabled={!couponCode}
									style={{marginLeft: '15px', background: '#cccccc'}}
									onClick={() => onApplyCoupon(currentItem, couponCode)}
								>
									{t1('apply')}
								</Button>
							</div>
						)}
					</div>
				</div>
      </Modal>
			<Modal className="text-center" title={t1('confirm_purchase_gold')} open={showModalGold} onOk={(e) => {
                setParams('');
                setParams({
                    item_id: currentItem?.item?.id,
                    open_price_id: currentItem?.id,
                    quantity: parseInt(goldBuy) * 1000000
                })
            }} onCancel={(e) => setShowModalGold(false)}>
                <p>{t1('how_many_gold_you_want_to_buy_in_million')}</p>
                <InputNumber value={goldBuy} onChange={(e) => {
					setGoldBuy(e);
				}}/>
				<p>{t1('you_need_to_pay_%s_point',[Math.ceil(parseInt(currentItem?.price)* parseInt(goldBuy) * 1000000/currentItem?.item?.quantity)])}</p>
            </Modal>
		</PageLayout>
	);
}

const mapStateToProps = (state,props) => {
	return {
		profile: state?.profile
	}
}

export default connect(mapStateToProps)(MarketLayout);
