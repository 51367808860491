import { LogoutOutlined, UserOutlined  } from "@ant-design/icons";
import { Layout, Menu, theme, Row, Col } from "antd";
import { useState, useEffect } from "react";
import AuthWrapper from "components/AuthWrapper";
import UserInfoWrapper from "components/UserInfoWrapper";
import { connect } from "react-redux";
import { logoutAction } from "redux/actions";
import MENU_LIST from "constants/menus";
import { t1 } from "translate";
import { useNavigate, useLocation } from "react-router-dom";
import Breadcrumb from "antd/lib/breadcrumb";
import Typography from "antd/lib/typography";
import Avatar from "antd/lib/avatar";
import Divider from "antd/lib/divider";
import Statistic from "antd/lib/statistic";

const { Header, Content, Footer, Sider } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

const addLogoutMenu = (menuList = [], dispatch) => {
    let output = menuList;

    if (!output.find((e) => e.key == 9999)) {
        output.push({
            key: 9999,
            icon: <LogoutOutlined />,
            label: t1("logout"),
            onClick: (e) => dispatch(logoutAction()),
        });
    }

    return output;
};

const convertMenuToLink = (menuList = [], navigate, location) => {
    let output = [];

    menuList.map((menuData) => {
        if (menuData?.children) {
            output.push({
                ...menuData,
                children: convertMenuToLink(menuData.children, navigate, location),
            });
        } else {
            output.push({
                ...menuData,
                onClick: (e) => {
                    if (location.pathname != menuData.link) {
                        navigate(menuData.link);
                    }
                },
            });
        }
    });

    return output;
};

const PageLayout = ({ children, dispatch, breadcrumList = [], profile, auth }) => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        console.log(location.pathname)
        if (typeof profile?.reloadProfile === 'function'){
            profile.reloadProfile();
        }
    },[location.pathname])

    const handleRenderPrice = (price) => {
        if (price >= 1000000000) {
            return price / 1000000000;
        } else if (price >= 1000000) {
            return price / 1000000;
        } else {
            return price;
        }
    }

    const handleRenderPriceIcon = (price, concurrency) => {
        const icon = concurrency === 0
            ? <img src="/cashicon.png" alt="sro money point icon" style={{width:"18px"}}/>
            : <img src="/goldicon.png" alt="sro money gold icon" style={{width:"14px"}}/>;
        let title = '';
        if (price >= 1000000000) {
            title = t1('billion');
        } else if (price >= 1000000) {
            title = t1('million');
        }
        return <>
            {!!title && (<span style={{fontSize: '15px', marginRight: '3px'}}>{title}</span>)}
            {icon}
        </>
    }

    return (
        <AuthWrapper>
            <Layout
                style={{
                    minHeight: "100vh",
                }}
            >
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    width={210}
                >
                    <Row>
                        <Col span={24} className='text-center' style={{
                                height: '64px',
                                backgroundColor: '#fff',
                                padding: collapsed ? '1px 7px 1px 7px' : '15px 10px'
                        }}>
                            {collapsed ? <img src="/logo_small.png" alt="logo" style={{height: '60px'}}/> : <img src="/logo_big.png" alt="logo" style={{width: '100%'}}/>}                          
                        </Col>
                        <Col span={24} style={{ maxHeight: "90vh", overflow: "auto" }}>
                            <Row style={{padding: '25px 0px', color: 'white'}}>
                                <Col span={collapsed ? 24 : 8}>
                                    <Avatar style={{ backgroundColor: '#87d068' }} shape="square" size={64} icon={<UserOutlined />} />
                                </Col>
                                <Col span={collapsed ? 0 : 16}>
                                    <Title level={5} className="ps-2" ellipsis={true} style={{color: 'white', textTransform:'capitalize'}}>{profile?.profile?.username}</Title>
                                    <Text className="ps-2" style={{color: 'white'}}>{t1('normal_user')}</Text>
                                </Col>
                                <Divider className="mt-3 mb-1" style={{borderColor: 'rgba(255,255,255,0.4)'}}/>
                                <Col span={collapsed ? 0 : 12}>
                                    <Statistic
                                        title={<span style={{color: "#ddd"}}>{t1('total_point')}</span>}
                                        value={handleRenderPrice(parseFloat(profile?.account?.balance))}
                                        valueStyle={{color: '#fff', fontSize: '20px'}}
                                        suffix={handleRenderPriceIcon(parseFloat(profile?.account?.balance), 0)}
                                    />
                                </Col>
                                <Col span={collapsed ? 0 : 12}>
                                    <Statistic
                                        title={<span style={{color: "#ddd"}}>{t1('gold_tk')}</span>}
                                        value={handleRenderPrice(parseInt(profile?.account?.gold_accounts[0]?.balance))}
                                        valueStyle={{color: '#fff', fontSize: '20px'}}
                                        suffix={handleRenderPriceIcon(parseFloat(profile?.account?.gold_accounts[0]?.balance), 1)}
                                    />
                                </Col>
                                <Col span={collapsed ? 0 : 12}>
                                    <Statistic
                                        title={<span style={{color: "#ddd"}}>{t1('gold_bk')}</span>}
                                        value={handleRenderPrice(parseInt(profile?.account?.gold_accounts[1]?.balance))}
                                        valueStyle={{color: '#fff', fontSize: '20px'}}
                                        suffix={handleRenderPriceIcon(parseFloat(profile?.account?.gold_accounts[1]?.balance), 1)}
                                    />
                                </Col>
                                <Col span={collapsed ? 0 : 12}>
                                    <Statistic
                                        title={<span style={{color: "#ddd"}}>{t1('gold_hk')}</span>}
                                        value={handleRenderPrice(parseInt(profile?.account?.gold_accounts[2]?.balance))}
                                        valueStyle={{color: '#fff', fontSize: '20px'}}
                                        suffix={handleRenderPriceIcon(parseFloat(profile?.account?.gold_accounts[2]?.balance), 1)}
                                    />
                                </Col>
                            </Row>
                            <Menu
                                theme="dark"
                                defaultSelectedKeys={[]}
                                mode="inline"
                                items={addLogoutMenu(
                                    convertMenuToLink(MENU_LIST, navigate, location),
                                    dispatch
                                )}
                            />
                        </Col>
                    </Row>
                </Sider>
                <Layout>
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                        }}
                    ></Header>
                    <Content
                        style={{
                            padding: "0 16px",
                            //background: '#001529',
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "28px",
                            //color: "#fff",
                        }}
                        className="pt-3"
                    >
                        <Breadcrumb items={[...[{title:t1('homepage'), link: '/'}],...breadcrumList].map((breadcrumbInfo) => {
                            return {
                                title: breadcrumbInfo?.link ? <a href={breadcrumbInfo?.link}>{breadcrumbInfo?.title}</a> : breadcrumbInfo?.title
                            }
                        })}/>
                        {children}
                    </Content>
                </Layout>
            </Layout>
            {/* <Footer style={{ padding: '0px' }}>
                <FooterMenu />
            </Footer> */}
        </AuthWrapper>
    );
};

const mapStateToProps = (state,props) => {
    return {
        profile: state?.profile,
        auth: state?.auth?.token
    }
}

export default connect(mapStateToProps)(PageLayout);
