import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { endpoints } from "constants/endpoints";
import useFetch from "commons/hook/fetchDataHook";
import { SERVER_LIST } from "constants/game";
import { t1 } from "translate";
import {
  getImageLinkFromItemModel,
  getItemType,
} from "components/ItemDetail/utils";
import styles from "./styles.module.scss";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";
import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";
import ItemStatus from "components/ItemDetail/ItemStatus";
import DetailContent from "components/ItemDetail/DetailContent";
import { CURRENCY_OPTIONS } from "constants";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueryParams } from 'helper/helper';
import notification from 'antd/lib/notification';
import Cookies from 'js-cookie';

const { Text } = Typography;

const PublicOfferLayout = ({ profile }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [itemDetail, setItemDetail] = useState(null);
    const [price, setPrice] = useState(null);
    const [itemType, setItemType] = useState(null);
    const [itemModelInfo, setItemModelInfo] = useState(null);
    const [rareType, setRareType] = useState("");
	const [goldBuy,setGoldBuy] = useState(0);
    const [paramsFilter, setParamsFilter] = useState({
        server_id: 3
    });
    const [fetchData, setFetchData] = useState(false);
    const [offerId, setOfferId] = useState(null);

    const getServer = (serverId) =>
    SERVER_LIST.find(
        (server) => server.id === Number(serverId)
    )?.name || "";

    const [offerData, setEndpointGetOffer, setParamsGetOffer] = useFetch(
        endpoints.public_offer,
        {},
        "GET",
        (result, params) => {
            if (fetchData) {
                if (result?.item) {
                    setItemDetail(result?.item);
                    setPrice(result?.price);
                } else {
                    notification.warning({
                        message: t1('warning_message'),
                        description: result?.message
                    })
                    setOfferId(null);
                    return navigate('/market');
                }
            }
        },
        (result, params) => {
            if (fetchData) {
                if (result?.item) {
                    setItemDetail(result?.item);
                    setPrice(result?.price);
                } else {
                    notification.warning({
                        message: t1('warning_message'),
                        description: result?.message
                    })
                    setOfferId(null);
                    return navigate('/market');
                }
            }
        }
    );

    const [data, setEndpoint, setParams] = useFetch(
		endpoints.purchase_item,
		'',
		"POST",
		(result, params) => {
            setGoldBuy(0);
            setParamsGetOffer('');
            setParamsGetOffer({
                submit: 1, page: 1, limit: 10, ...paramsFilter
            });
		},
		(result, params) => {
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
		}
	)

    const handlePurchase = () => {
        // const itemModel = itemDetail.item_model_info;
        // const modelName = itemModel?.name?.vi
        //     ? itemModel.name.vi
        //     : itemModel?.name?.en
        //         ? itemModel.name.en
        //         : null;
        // const modelSearch = itemModel.id + (modelName ? `-${modelName.replaceAll(' ', '-')}` : '');
        // const path = `/offer-trade/server=${itemDetail.server_id}&model=${modelSearch}`;
        const path = location.pathname.replace('/offer/', '/offer-trade/')
        if (!profile?.profile) {
            Cookies.set('previous_path', path);
        }
        window.top.location.href = window.WEB_SERVER + path;
    }

    useEffect(() => {
        const offers = location.pathname.replace('/offer/', '');
        const getOfferId = offers.split('-')[0];
        if (!!getOfferId) {
            setParamsGetOffer('');
            setParamsGetOffer({id: getOfferId})
            setFetchData(true);
            setOfferId(getOfferId);
        } else {
            notification.warning({
                message: t1('warning_message'),
                description: t1('item_is_not_exist')
            })
            return navigate('/market');
        }
    }, [location, offerId]);

    useEffect(() => {
        if (itemDetail) {
            setItemModelInfo(itemDetail?.item_model_info);
            setItemType(getItemType(
                itemDetail?.item_model_info,
                itemDetail?.item_info
            ));
        }
    }, [itemDetail]);

    useEffect(() => {
        if (itemType) {
            let rareType = "";
            if (itemType?.type === ITEM_RARITY.RARE){
                if (itemModelInfo?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOS}_RARE`)){
                    rareType = 'SOS';
                } else {
                    if (itemModelInfo?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOM}_RARE`)) {
                        rareType = "SOM";
                    } else {
                        rareType = "SUN";
                    }
                }
            }
            setRareType(rareType);
        }
    }, [itemType, itemModelInfo]);

  return (
    <div className={styles.offerContainer}>
        <Row
          align="middle"
          className={styles.itemContainer}
          gutter={8}
          onClick={(e) => {}}
        >
            <Col span={9}>
                <div className={styles.image}>
                    <img
                        src={getImageLinkFromItemModel(itemModelInfo)}
                        alt={itemModelInfo?.name?.vi}
                        style={{ width: "70%" }}
                        onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        if (
                            currentTarget.src === getImageLinkFromItemModel(itemModelInfo)
                        ) {
                            currentTarget.src = getImageLinkFromItemModel(
                            itemModelInfo,
                            true
                            );
                        }
                        }}
                    />
                    {itemType?.type === ITEM_RARITY.RARE ? (
                        <img
                            className="rare-star ps-1"
                            style={{ width: "70%" }}
                            src="https://sro.money/images/rare_star.gif"
                            alt={itemModelInfo?.name?.vi}
                        />
                    ) : (<></>)}
                </div>
                <Typography className={styles.itemTitle}>
                    <Text className="text-white">
                        {t1("server_:_%s", [getServer(price?.server_id)])}
                    </Text>
                    <Text className="text-white">
                        <br />
                        {t1("quantity:_%s", [itemDetail?.quantity])}
                        <br />
                        {t1("%s_needed:_", [
                        CURRENCY_OPTIONS[price?.concurrency]?.label,
                        ])}
                        <span>{parseInt(price?.price)}</span>
                    </Text>
                </Typography>
                <div className={styles.itemStatus}>
                    <ItemStatus itemData={itemDetail} style={{left: '15px', bottom: '-28px'}}/>
                </div>
                {price && (
                    <Button className={styles.actionBtn} type="primary" onClick={handlePurchase}>
                        {t1('buy')}
                    </Button>
                )}
            </Col>
          <Col span={15} style={{color:"#fff"}}>
            {itemDetail && itemType && itemModelInfo && (
                <DetailContent
                    itemType={itemType}
                    itemModel={itemModelInfo}
                    itemInfo={itemDetail?.item_info}
                    rareType={rareType}
                />
            )}
          </Col>
        </Row>
    </div>
  );
};

const mapStateToProps = (state,props) => {
	return {
		profile: state?.profile
	}
}

export default connect(mapStateToProps)(PublicOfferLayout);
