import React from 'react';
import { connect } from 'react-redux';
import { t1 } from 'translate';

const UserInfoBlock = ({userProfile}) => {
    return <div
    className="userinfo-block"
>
    <p
        className={`text-center big-text`}
    >
        {userProfile?.username}
        <span
            className={`ms-2 p-0 ps-1 pe-1 text-normal`}
        >
            Member
        </span>
    </p>
    <p className="info-text">
        {t1("reputation_score:_%s", [
            userProfile?.reputation_score,
        ])}
    </p>
    <p className="info-text">
        {t1("total_auction_bid:_%s", [
            userProfile?.total_auction_bid,
        ])}
    </p>
    <p className="info-text">
        {t1("total_auction_sell:_%s", [
            userProfile?.total_auction_sell,
        ])}
    </p>
    <p className="info-text">
        {t1("total_auction_won:_%s", [
            userProfile?.total_auction_won,
        ])}
    </p>
    <p className="info-text">
        {t1("total_purchase:_%s", [
            userProfile?.total_purchase,
        ])}
    </p>
    <p className="info-text">
        {t1("total_review:_%s", [
            userProfile?.total_review,
        ])}
    </p>
    <p className="info-text">
        {t1("total_sell:_%s", [
            userProfile?.total_sell,
        ])}
    </p>
    <p className="info-text">
        {t1("referal_code:_%s", [
            "MN1692",
        ])}
    </p>
</div>
}

const mapStateToProps = (state, props) => {
    return {
        userProfile: state?.profile?.profile,
    };
};

export default connect(mapStateToProps)(
    UserInfoBlock
);