import React from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";

const { Title, Paragraph, Text } = Typography;

const Step3 = ({nextStep, setCurrentPercent, currentTransactionData,offerItemList = []}) => {
	if (offerItemList.length > 0){
		nextStep();
	}

	return (
		<Row gutter={[8,8]}>
			<Col span={24}>
				{currentTransactionData?.transaction?.id ? <Typography>
					<Title className="text-center">{t1("bot_allocated")}</Title>
					<Paragraph>
						{t1("our_bot_should_appear_near_your_location._")}
						{t1(
							"it_will_automatically_send_you_security_message_below_in_private_to_confirm._if_you_get_the_correct_private_message,_please_click_confirm_to_move_to_next_step._this_message_is_identical_to_your_account_so_others_cannot_steal_it._"
						)}
							{t1(
							"after_accept_trade_just_do_thing_like_normal._bot_will_tell_you_what_is_the_state_of_your_deposit."
						)}
					</Paragraph>

					<Text type="danger">
						{t1(
							"failed_to_confirm_in_time_will_make_you_blocked_from_deposit_function_for_at_least_5_mins"
						)}
					</Text>
				</Typography>: <Typography>
					<Title>{t1("waiting_for_server_to_allocate_bot_for_transaction")}</Title>
					<Paragraph>
						{t1("please_wait_a_little_bit")}
					</Paragraph>
				</Typography>}
			</Col>
			<Col span={24} className="text-center">
				<p className="fw-bold">{t1("bot_character_name:_%s",[currentTransactionData?.bot?.char_name])}</p>
			</Col>
			<Col span={24} className="text-center">
				<p className="fw-bold">{t1("security_message")}</p>
				<h4>{currentTransactionData?.transaction?.otp}</h4>
			</Col>
			<Col span={24} className="text-center">
				<Button onClick={(e) => {
					nextStep()
					}}>{t1("move_to_tracking_deposit_live")}</Button>
			</Col>
		</Row>
	);
};

export default Step3;
