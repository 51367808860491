import React from "react";
import { t1 } from "translate";
import Statistic from "antd/lib/statistic";
import Step11 from '../components/StepOne/steps/step1';
import Step12 from '../components/StepOne/steps/step2';
import Step13 from '../components/StepOne/steps/step3';
import Step21 from '../components/StepTwo/steps/step1';
import Step22 from '../components/StepTwo/steps/step2';
import Step21Gold from '../components/StepTwoGold/steps/step1';
import Step22Gold from '../components/StepTwoGold/steps/step2';
import Step31 from '../components/StepThree/steps/step1';
import Step32 from '../components/StepThree/steps/step2';

const { Countdown } = Statistic;

export const stepOneSteps = (
  timeStartStep, currentStep, nextStep, setCurrentPercent, setCurrentStep, setTimeStartStep, startWithdraw, currentTransactionData, offerItemList
  ) => {
    return [
        {
            title: t1("move_to_location"),
            description: t1("guidance"),//currentStep == 1 ? <Countdown value={timeStartStep + 300*1000} format="mm:ss"/> : '',
            //totalTime: 300, //5 mins
            component: (
                <Step12
                  currentStep={currentStep}
                  setCurrentPercent={setCurrentPercent}
                  setCurrentStep={setCurrentStep}
                  startWithdraw={startWithdraw}
                />
            )
        },
        {
            title: t1("confirm_withdraw_transaction"),
            description: t1("guidance"),//currentStep == 2 ? <Countdown value={timeStartStep + 120*1000} format="mm:ss"/> : '',
            //totalTime: 120, //1 mins
            component: <Step13 nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData} offerItemList={offerItemList}/>
        },
    ];
};

export const stepTwoSteps = (timeStartStep, currentStep = 0, nextStep, setCurrentPercent, currentTransactionData, offerItemList) => {
    return [
      {
        title: t1("withdraw_offer_item"),
        description: currentStep == 0 ? <Countdown value={timeStartStep + 120*1000} format="mm:ss"/> : '',
        totalTime: 120, //2 mins
        component: <Step21 nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData} offerItemList={offerItemList}/>
      },{
        title: t1("finished_withdraw_offer"),
        description: t1(""),//currentStep == 0 ? <Countdown value={timeStartStep + 120*1000} format="mm:ss"/> : '',
        //totalTime: 120, //2 mins
        component: <Step22 nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData} offerItemList={offerItemList}/>
      },
    ];
};

export const stepThreeSteps = (timeStartStep, currentStep = 0, nextStep, setCurrentPercent, setCurrentStep, setTimeStartStep, cancelWithdrawProcess) => {
    return [
      {
        title: t1("confirm_item"),
        description: currentStep == 0 ? <Countdown value={timeStartStep + 180*1000} format="mm:ss"/> : '',
        totalTime: 180, //3 mins
        component: (
            <Step31
                currentStep={currentStep}
                setCurrentPercent={setCurrentPercent}
                setCurrentStep={setCurrentStep}
                setTimeStartStep={setTimeStartStep}
            />
        )
      },
      {
        title: t1("end_of_step_3"),
        description: currentStep == 1 ? <Countdown value={timeStartStep + 60*1000} format="mm:ss"/> : '',
        totalTime: 60, //1 mins
        component: <Step32 nextStep={nextStep} setCurrentPercent={setCurrentPercent} cancelWithdrawProcess={cancelWithdrawProcess}/>
      },
    ];
  };

export const stepTwoGoldSteps = (timeStartStep, currentStep = 0, nextStep, setCurrentPercent, currentTransactionData) => {
  return [
    {
      title: t1("withdraw_offer_golds"),
      description: currentStep == 0 ? <Countdown value={timeStartStep + 120*1000} format="mm:ss"/> : '',
      totalTime: 120, //2 mins
      component: <Step21Gold nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData}/>
    },{
      title: t1("finished_withdraw_gold_offer"),
      description: t1(""),//currentStep == 0 ? <Countdown value={timeStartStep + 120*1000} format="mm:ss"/> : '',
      //totalTime: 120, //2 mins
      component: <Step22Gold nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData}/>
    },
  ];
};
