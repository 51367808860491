import React from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import ItemDetail from "components/ItemDetail";

const { Title, Paragraph, Text } = Typography;

const Step1 = ({setCurrentPercent, offerItemList = []}) => {
	return (
		<Row>
			<Col span={24}>
				<Typography>
					<Title className="text-center">{t1("trade_offer_detail")}</Title>
					<Paragraph>
						{t1(
							'when_you_confirm_item_to_deposit,_your_items_will_show_up_below_with_all_information_related_to_it._'
						)}
						{t1(
							"you_have_%s_seconds_to_accept_trade_offer"
						)}
					</Paragraph>
					<Text type="danger">
						{t1(
							"failed_to_accept_trade_offer_in_time_will_make_you_blocked_from_deposit_function_for_at_least_5_mins"
						)}
						</Text>
				</Typography>
			</Col>
			<Col span={24}>
				<Row gutter={16}>
					{offerItemList.map((itemInfo) => {
						return <Col span={6}>
							<ItemDetail
			          data={itemInfo}
			          action={(e) => console.log(e)}
			        />
				    </Col>
				})}
				</Row>
			</Col>
		</Row>
	);
};

export default Step1;
