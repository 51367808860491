import React, { useState, useEffect } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import dayjs from "dayjs";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Steps from "antd/lib/steps";
import Message from "antd/lib/message";
import Statistic from "antd/lib/statistic";
import Typography from "antd/lib/typography";
import { SERVER_LIST } from "constants/game";
import SelectItem from "./components/SelectItem";
import withdrawSteps from './constants/withdrawSteps';
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { getCharacterImageFromModelId } from "commons/game";
import { CHARACTER_LIST } from "pages/deposit/fake";
import Pagination from 'antd/lib/pagination';
import ItemDetail from "components/ItemDetail";
import SelectCharacter from "pages/deposit/components/SelectCharacter";
import notification from "antd/lib/notification";

//This page will be use to withdraw item in system
/**
	* This components will work like description below:
	* 
	* 1/ Connect to socket (from redux)
	* 2/ Choose character that linked to account + select item (that currently free in inventory) that want to withdraw => click request
	* 3/ Item that in this list will get blocked status prevent them from withdraw and listing onto market
	* 3/ Move to step 1
	* (bot will automatically sign out when trade finished fail somehow)
	*/

const { Countdown } = Statistic;
const { Title } = Typography;

const WithdrawLayout = (props) => {
	const [serverSelected, setServerSelected] = useState(SERVER_LIST[0]);
	const [itemList, setItemList] = useState([]);
	const [severItems, setServerItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);

	const [isWithdraw, setIsWithdraw] = useState(false);

	const [currentStep, setCurrentStep] = useState(0);
	const [currentPercent, setCurrentPercent] = useState(0);
	const [timeStartStep, setTimeStartStep] = useState(0);

	const [character, setCharacter] = useState(false);
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const [withdrawTransaction, setWithdrawTransaction] = useState({});
	const [withdrawOfferItemList, setWithdrawOfferItemList] = useState([]);
	const [selectedBotId, setSelectedBotId] = useState(null);

	useEffect(() => {
		if (selectedItems.length === 0) {
			setSelectedBotId(null);
		}
	}, [selectedItems]);

	const [dataInventory, setEndpointInventory, setParamsInventory] = useFetch(
		endpoints.my_items,
		{ submit: 1, page: page, pageSize: pageSize, status: 'active' },
		"GET",
		(result, params) => {
			setItemList(result?.data);
			setPage(result?.meta?.current_page);
			setTotal(result?.meta?.total);
			setPageSize(result?.meta?.per_page);
		},
		(result, params) => {

		}
	)

	const [dataWithdraw, setEndpointWithdraw, setParamsWithdraw] = useFetch(
		endpoints.start_withdraw,
		'',
		"POST",
		(result, params) => {
			setWithdrawTransaction(result?.data);
			setInterval(() => {
				const loadTransactionData = (transactionId) => {
					updateParamsOffer('');
					updateParamsOffer({
						inventory_transaction_id: transactionId
					})
				}

				return loadTransactionData(result?.data?.transaction?.id);
			}, 10000)
		},
		(result, params) => {
			notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
		}
	)

	const [dataOffer, updateEndpointOffer, updateParamsOffer] = useFetch(
		endpoints.items_in_transaction,
		'',
		"GET",
		(result, params) => {
			//after finsihed => change to step 2
			setWithdrawOfferItemList(result?.data)
		},
		(result, params) => {
			notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
		}
	)

	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	}

	const startWithdraw = () => {
		setParamsWithdraw('');
		setParamsWithdraw({
			game_character_id: character,
			items: selectedItems.map(itemInfo => itemInfo.id)
		})
	}

	//just simply split it into 2 stage - inventory mode and withdraw queue
	return <PageLayout>
		<h5>{t1('withdraw_layout')}</h5>
		{isWithdraw ? <Row>
			<Col span={24}>
				<Card title={t1("withdraw_queue")}>
					<Row>
						<Col span={6}>
							<Row gutter={[20, 16]}>
								<Col span={24}>
									<Steps
										percent={currentPercent}
										current={currentStep}
										direction="vertical"
										items={withdrawSteps([], nextStep, setCurrentPercent, setCurrentStep, startWithdraw, withdrawTransaction, withdrawOfferItemList).map((stepData, index) => {
											if (index != currentStep) {
												return {
													title: stepData?.title,
													description: stepData?.description
												}
											} else {
												return {
													title: stepData?.title,
													description: stepData?.description,
													subTitle: stepData?.totalTime ? <Countdown format="mm:ss" value={timeStartStep + stepData?.totalTime * 1000} valueStyle={{ color: '#1677ff' }} /> : '',
												}
											}
										})}
									/>
								</Col>
							</Row>
						</Col>
						<Col span={18}>
							{withdrawSteps([], nextStep, setCurrentPercent, setCurrentStep, startWithdraw, withdrawTransaction, withdrawOfferItemList).find((element) => element.id == currentStep)?.content || <></>}
						</Col>
					</Row>
				</Card>
			</Col>
		</Row> : <Row>
			<Col span={24}>
				<SelectCharacter
					updateCharacter={(e) => {
						setCharacter(e);
					}}
				/>
				<Card
					title={t1("select_your_item_to_withdraw")}
					bordered={true}
				>
					<Row>
						<Col span={24} className="pb-5">
							<Row gutter={[16,8]}>
								{selectedItems?.map((item) => {
									return <Col span={4}>
										<ItemDetail data={item} action={(itemInfo) => {
											//var alreadyExist = selectedItems.find((item) => item.id == itemInfo.id);
											var newItemArray = [];
											var alreadyExist = false;

											selectedItems.map((itemData) => {
												if (itemData.id == itemInfo.id) {
													//ko add
													alreadyExist = true;
												} else {
													newItemArray.push(itemData);
												}
											})

											if (!alreadyExist) {
												newItemArray.push(itemInfo)
											}

											setSelectedItems(newItemArray);
										}} />
									</Col>
								})}
							</Row>
						</Col>
						<Col span={24}>
							<Row gutter={[16,8]}>
								{itemList?.map((item) => {
									return <Col span={4}>
										<ItemDetail disabled={selectedBotId && selectedBotId !== item?.bot_id} isFocus={selectedItems.find((itemInfo) => itemInfo.id == item.id)} data={item} action={(itemInfo) => {
											//var alreadyExist = selectedItems.find((item) => item.id == itemInfo.id);
											var newItemArray = [];
											var alreadyExist = false;

											selectedItems.map((item) => {
												if (item.id == itemInfo.id) {
													//ko add
													alreadyExist = true;
												} else {
													newItemArray.push(item);
												}
											})

											if (!alreadyExist) {
												newItemArray.push(itemInfo)
											}

											if (!selectedBotId) {
												setSelectedBotId(itemInfo?.bot_id || -1);
											}
											setSelectedItems(newItemArray);
										}} />
									</Col>
								})}
							</Row>
						</Col>
						<Col span={24} style={{ textAlign: 'right' }}>
							<Pagination defaultCurrent={page} total={total} pageSize={pageSize} onChange={(pageNumber,pageSizeNumber) => {
								setParamsInventory('');
								setParamsInventory({ submit: 1, page: pageNumber, pageSize: pageSizeNumber, status: 'active' })
							}}/>
						</Col>
						<Col span={24} className={'mt-5 text-center'}>
							<Button type="primary" disabled={!(selectedItems.length > 0 && character)} onClick={(e) => setIsWithdraw(true)}>{t1('start_withdraw')}</Button>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>}
	</PageLayout>
}

export default WithdrawLayout;
