import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Tag from "antd/lib/tag";
import Table from "antd/lib/table";
import { t1 } from "translate";
import DataFetchHook from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { SERVER_LIST } from "constants/game";

//This page will list all the bot currently status, thier available, how many queue they are still in or are they all maintained
const WithdrawBotStatusLayout = (props) => {
  const [ totalBotSlot, setTotalBotSlot ] = useState(0);
  const [ botList, setBotList ] = useState([]);

  const [data,updateEndpointBot,updateParamsBot] = DataFetchHook(endpoints.bot_list,{submit: 1, limit: 99999}, 'GET', (result,params) => {
    setBotList(result?.data);
  })

  const [dataSlot,updateEndpointSlot,updateParamsSlot] = DataFetchHook(endpoints.number_bot_free,{submit: 1, limit: 99999}, 'GET', (result,params) => {
    setTotalBotSlot(result?.data?.free);
  })

  const getServer = (serverId) =>
    SERVER_LIST.find(
      (server) => server.id === Number(serverId)
    )?.name || "";

  const columns = [
    {
      title: t1('bot_name'),
      dataIndex: 'char_name',
      key: 'char_name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: t1('server'),
      dataIndex: 'server_id',
      key: 'server_id',
      render: (data) => {
        return getServer(data)
      }
    },
    {
      title: t1('active_status'),
      dataIndex: 'active_status',
      key: 'active_status',
      render: (status) => (
        <Tag color={status ? 'green' : 'volcano'}>
          {status ? t1('active') : t1('inactive')}
        </Tag>
      ),
    },
    {
      title: t1('online_status'),
      dataIndex: 'online_status',
      key: 'online_status',
      render: (status) => (
        <Tag color={status ? 'green' : 'volcano'}>
          {status ? t1('online') : t1('offline')}
        </Tag>
      ),
    },{
      title: t1('storage'),
      dataIndex: 'character_total_storage_slot',
      key: 'character_total_storage_slot',
      render: (data,record,index) => {
        return `${record?.empty_storage_number}/${record?.character_total_storage_slot}`
      },
    }
  ];

  return (
    <PageLayout breadcrumList={[{ title: t1("withdraw_bot_status") , link: '/withdraw/status'}]}>
      <Row className="mt-4">
        <Col span={24}>
          <Card title={t1("withdraw_bot_status_layout") + t1("_(%s)",[totalBotSlot])} style={{ borderRadius: "5px" }}>
            <Row>
              <Col span={24}>
                <Table
                  style={{ width: '100%' }}
                  columns={columns}
                  dataSource={botList}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default WithdrawBotStatusLayout;
