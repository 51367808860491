import React from "react";
import { t1 } from "translate";
import dayjs from "dayjs";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Image from "antd/lib/image";

const { Title, Paragraph, Text } = Typography;

const Step2 = (props) => {
  const { currentStep, setCurrentStep, startWithdraw } = props;
  return (
    <Row>
			<Col span={24}>
				<Typography>
					<Title>{t1("move_your_character")}</Title>
					<Paragraph>
						{t1(
							"you_need_to_move_your_character_to_chang_an_at_location_x:_6222,_y:_1059._or nearby._click_image_for_more_information"
						)}
					</Paragraph>
				</Typography>
			</Col>
      <Col span={24} className="text-center">
        <Row gutter={16}>
          <Col span={8}>
            <Image src="https://sro.money/images/location_1.PNG"/>
          </Col>
          <Col span={8}>
            <Image src="https://sro.money/images/location_2.PNG"/>
          </Col>
          <Col span={8}>
            <Image src="https://sro.money/images/location_3.PNG"/>
          </Col>
        </Row>
        <Button className="mt-5" type="primary" onClick={(e) => {
          setCurrentStep(currentStep + 1);
          startWithdraw();
        }}>{t1('click_to_confirm_that_you_already_at_location')}</Button>
      </Col>
		</Row>
  );
};

export default Step2;
