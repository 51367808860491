import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const userInfoGeneralInfoViewLayout = (formComponent) => {
    return (
        <Row gutter={[20, 20]} align="middle">
            <Col span={6} className="text-center">{formComponent?.avatar}</Col>
            <Col span={17} offset={1}>
                <Row gutter={[10, 10]}>
                    <Col span={24}>{formComponent?.username}</Col>
                    <Col span={24}>{formComponent?.email}</Col>
                    <Col span={24}>{formComponent?.dob}</Col>
                    <Col span={24}>{formComponent?.phone_number}</Col>
                    <Col span={24} className="text-center">{formComponent?.SubmitButton}</Col>
                </Row>
            </Col>
        </Row>
    );
};

export default userInfoGeneralInfoViewLayout;
