import React, { useState } from "react";
import Modal from "antd/lib/modal";
import { SERVER_LIST } from "constants/game";
import { t1 } from "translate";
import {
  getImageLinkFromItemModel,
  getItemType,
} from "./utils";
import styles from "./styles.module.scss";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";
import ItemStatus from "./ItemStatus";
import Popover from 'antd/lib/popover';
import DetailContent from "./DetailContent";


const { Title, Paragraph, Text } = Typography;

const ItemDetail = ({isSmallPic = false, data, action = (e) => console.log(e), isFocus, disabled = false}) => {
  const getServer = (serverId) =>
    SERVER_LIST.find(
      (server) => server.id === Number(serverId)
    )?.name || "";

  let itemType = getItemType(
    data?.item_model_info,
    data?.item_info
  );
  
  let rareType = "";
  if (itemType?.type == ITEM_RARITY.RARE){
    if (data?.item_model_info?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOS}_RARE`)){
      rareType = 'SOS';
    }else{
      if (data?.item_model_info?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOM}_RARE`)){
        rareType = "SOM";
      }else{
        rareType = "SUN";
      }
    }
  }

  let isLock = data?.status == "lock";

  let fullItemName = data?.item_info?.plus && parseInt(data?.item_info?.plus) > 0 ? `${data?.item_model_info?.name?.vi} (+${data?.item_info?.plus})` : data?.item_model_info?.name?.vi;
  if (fullItemName?.length > 1){
    
  }else{
    fullItemName = t1('no_name_item');
  }

  if (itemType?.type == ITEM_RARITY.RARE){
    fullItemName = `[${rareType}] ${fullItemName}`;
  }

  let quantity = 1;

  if (data?.item_info?.count){
    quantity = data?.item_info?.count;
  }

  return (
    <Popover content={<DetailContent itemType={itemType} itemModel={data?.item_model_info} itemInfo={data?.item_info} rareType={rareType}/>} title={null}>
      <div
        className={`hvr-box-shadow-outset ${styles.itemContainer} ${
          isFocus ? "item-selected" : ""
        } ${isLock ? "disabled-item" : "enabled-item"}`}
      >
        <ItemStatus itemData={data} style={{zIndex: 100}}/>
        {disabled && (
          <div className={styles.itemDisable} />
        )}
        <Row
          align="middle"
          gutter={8}
          onClick={(e) => {
            if (disabled) return;
            action(data);
          }}
        >
          <Col span="24">
          <Typography>
              <Title
                ellipsis={{ rows: 2}}
                level={5}
                className={`ps-2 m-0 item-name-${itemType?.type}`}
                style={{height: "50px", lineHeight: "25px"}}
              >
                {fullItemName}
              </Title>
            </Typography>
          </Col>
          <Col span={isSmallPic ? 3 : 8} className="text-center mt-1 mb-1">
            <img
              src={getImageLinkFromItemModel(
                data?.item_model_info
              )}
              alt={data?.item_model_info?.name?.vi}
              style={{ width: "90%" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                if (
                  currentTarget.src ==
                  getImageLinkFromItemModel(
                    data?.item_model_info
                  )
                ) {
                  currentTarget.src =
                    getImageLinkFromItemModel(
                      data?.item_model_info,
                      true
                    );
                }
              }}
            />
            {itemType?.type == ITEM_RARITY.RARE ? (
              <img
                className="rare-star ps-1"
                style={{ width: "90%" }}
                src="https://sro.money/images/rare_star.gif"
                alt={data?.item_model_info?.name?.vi}
              />
            ) : (
              <></>
            )}
            {data?.active_offer?.id ? <div style={{
              position: 'absolute',
              bottom: '0px',
              left: '6px',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.7)',
              padding: '1px 6px',
              borderRadius: '4px',
              border: '1px solid #fff'
            }}>
              <span className="me-1">{data?.active_offer?.concurrency == 0 ? <img src="/cashicon.png" alt="sro money point icon" style={{width:"24px"}}/>  : <img src="/goldicon.png" alt="sro money gold icon" style={{width:"18px"}}/>}</span>{parseInt(data?.active_offer?.price)}
            </div> : <></>}
          </Col>
          <Col span={isSmallPic ? 21 : 16}>
            <Typography>
              <Text className="text-white">
                {t1("server_:_%s", [
                  getServer(data?.server_id),
                ])}
              </Text>
              <br/>
              <Text className="text-white">
                {t1("quantity_:_%s", [
                  quantity,
                ])}
              </Text>
              <br/>
              <Text className="text-white">
                {t1("bot_id_:_%s", [
                  data?.bot_id,
                ])}
              </Text>
            </Typography>
          </Col>
        </Row>
      </div>
    </Popover>
  );
};

export default ItemDetail;
