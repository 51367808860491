import React, { useState } from "react";
import { t1 } from "translate";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import InputNumber from "antd/lib/input-number";
import Button from "antd/lib/button";
import { PAYMENT_METHODS, POINT_LIST } from "constants/paymentMethod";
import AddPointReceipt from "../AddPointReceipt";

const AddPointInput = (props) => {
    const { setParams, setCurrentStep, nextStepId } = props;
    const minPoint = 50;
    const [amount, setAmount] = useState(minPoint);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24} md={18} lg={18}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <h6>{t1("enter_amount_of_point_you_want_to_add")}</h6>
                    </Col>
                    <Col span={12}>
                        <div
                            style={{
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px 20px 5px 0px",
                                border: `1px solid ${amount < minPoint ? '#ff0002' : '#d9d9d9'}`,
                                borderRadius: "8px",
                                marginBottom: "20px",
                            }}
                        >
                            <InputNumber
                                max={10000}
                                bordered={false}
                                controls={false}
                                placeholder="0"
                                value={amount}
                                style={{ width: "100%", color: "#1677ff" }}
                                onChange={(e) => setAmount(e)}
                            />
                            <img src="/cashicon.png" alt="sro money point icon" style={{width:"24px"}}/>
                        </div>
                        {(amount < minPoint) && (
                            <div style={{
                                position: "absolute",
                                bottom: "-3px",
                                color: '#ff0002',
                            }}>
                                {t1('add_point_min_error:_%s', [minPoint])}
                            </div>
                        )}
                    </Col>
                    <Col span={12}></Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            {POINT_LIST.map((point, index) => (
                                <Col span={8} key={index}>
                                    <Button
                                        className="fw-bolder"
                                        type={point.value === amount ? "primary" : "dashed"}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "200px" }}
                                        onClick={() => setAmount(point.value)}
                                    >
                                        {t1("_%s", [point.value])}
                                        <img src="/cashicon.png" alt="sro money point icon" style={{ width:"20px", marginLeft: '3px' }}/>
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24} md={6} lg={6}>
                <AddPointReceipt
                    pointAmount={amount}
                    onCreateQR={(e) => {
                        setParams('');
                        setParams({
                            amount: amount
                        })
                        setCurrentStep(nextStepId);
                    }}
                    disabled={amount < minPoint}
                />
            </Col>
        </Row>
    );
};

export default AddPointInput;
