import React from 'react';
import { t1 } from 'translate';
import { cloneDeep } from 'lodash';
import Select from "antd/lib/select";
import { SERVER_LIST } from "constants/game";
import { ITEM_CATEGORY_I_MAPPING } from 'constants/item';
import refreshIcon from 'assets/icons/common/refreshIcon.svg';
import searchIcon from 'assets/icons/common/searchIcon.svg';
import clearIcon from 'assets/icons/common/clearIcon.svg';
import { CURRENCY_OPTIONS } from 'constants';
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";

const { Text } = Typography;

const filterList = [
    {
        key: 'concurrency',
        title: t1('currency_search_title'),
        placeholder: t1('currency'),
        options: CURRENCY_OPTIONS
    },
    {
        key: 'server_id',
        title: t1('server_search_title'),
        placeholder: t1('select_server'),
        options: SERVER_LIST.map(server => ({ value: server.id, label: server.name }))
    },
    {
        key: 'item_level',
        title: t1('level_search_title'),
        placeholder: t1('select_level'),
        options: [
            { value: 1, label: 'Level 1' },
            { value: 2, label: 'Level 2' },
            { value: 3, label: 'Level 3' },
            { value: 4, label: 'Level 4' },
            { value: 5, label: 'Level 5' },
            { value: 6, label: 'Level 6' },
            { value: 7, label: 'Level 7' },
            { value: 8, label: 'Level 8' },
            { value: 9, label: 'Level 9' },
        ]
    },
    {
        key: 'type',
        title: t1('type_search_title'),
        placeholder: t1('select_type'),
        options: Object.keys(ITEM_CATEGORY_I_MAPPING).map((key) => {
            return {
                value: ITEM_CATEGORY_I_MAPPING[key],
                label: t1(key.toLowerCase())
            }
        })
    },
    {
        key: 'source',
        title: t1('race_search_title'),
        placeholder: t1('race'),
        options: [
            { value: 0, label: t1('china_race') },
            { value: 1, label: t1('eu_race')},
        ]
    },{
        key: 'is_rare',
        title: t1('rare_search_title'),
        placeholder: t1('is_rare'),
        options: [
            { value: 0, label: t1('normal') },
            { value: 1, label: t1('sox')},
        ]
    },{
        key: 'sex',
        title: t1('gender_search_title'),
        placeholder: t1('gender'),
        options: [
            { value: 0, label: t1('all') },
            { value: 1, label: t1('male')},
            { value: 2, label: t1('female')},
        ]
    },
];

const SearchItem = (props) => {
    const { styles, params, updateParams } = props;

    const handleChangeParams = (key, value) => {
        const newParams = cloneDeep(params);
        newParams[key] = value;
        updateParams(newParams);
    };

    const handleClearParams = () => {
        updateParams({
            q: '',
            server_id: null,
            level: null,
            type: null
        });
    }

    return (
        <div className={styles.searchItem}>
            <div className={styles.refreshBtn}>
                <button onClick={handleClearParams}>
                    <img src={refreshIcon} />
                </button>
            </div>
            <Row gutter={[4,8]}>
                <Col span={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
                    <Text>{t1('item_name_search_title')}</Text>
                    <div className={styles.searchWrapper}>
                        <div className={styles.wrapper}>
                            <div className={styles.iconSearch}>
                                <img src={searchIcon} />
                            </div>
                            <input
                                type="text"
                                placeholder={t1('find_item')}
                                defaultValue={params.q}
                                onBlur={e => handleChangeParams('q', e.target.value)}
                            />
                            <div className={styles.clearSearch}>
                                <button
                                    className={styles.clearBtn + ` ${!!params.q ? styles.show : styles.hide}`}
                                    onClick={() => handleChangeParams('q', '')}
                                >
                                    <img src={clearIcon} />
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
                {filterList.map((filter, index) => (
                    <Col
                        key={index}
                        span={4}
                        style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}
                    >
                        <Text>{filter.title}</Text>
                        <Select
                            placeholder={filter.placeholder}
                            value={params[filter.key]}
                            options={filter.options}
                            onChange={(e) => handleChangeParams(filter.key, e)}
                            style={{ minWidth: "180px", marginRight: "10px" }}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    )
};

export default SearchItem;
