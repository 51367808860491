import React from 'react';
import { t1 } from 'translate';
import Button from 'antd/lib/button';
import {
    CopyOutlined
  } from '@ant-design/icons';
import { copyText } from 'helper/helper';
import notification from "antd/lib/notification";

const ItemStatus = ({itemData, style}) => {
    switch (itemData?.status){
        case 'lock':
            return <div className="status-block" style={style}><span style={{color: '#d3f261'}}>{t1(`status_${itemData?.status}`)}</span></div>
        case 'active':
            return <div className="status-block" style={style}><span style={{color: '#389e0d'}}>{t1(`status_${itemData?.status}`)}</span></div>
        case 'inactive':
            return <div className="status-block" style={style}><span style={{color: '#ff7875'}}>{t1(`status_${itemData?.status}`)}</span></div>
        case 'selling':
            return <div className="status-block" style={style}>
                <span style={{color: '#85a5ff'}}>{t1(`status_${itemData?.status}`)}</span><Button className="ms-2" icon={<CopyOutlined />} onClick={() => {
                let itemName = itemData?.item_model_info?.name?.vi
                  ? itemData.item_model_info.name.vi
                  : itemData?.item_model_info?.name?.en
                    ? itemData.item_model_info.name.en
                    : '';
                itemName = itemName?.replaceAll(' ', '-');
                itemName = itemName?.toLowerCase();
                notification.success({
                  message: '',
                  description: t1('copy_ok')
                })
                copyText(`${window.SRO_MONEY}/offer/${itemData?.latest_open_price_id}-${itemName}`);
              }}/></div>
        default:
            return <></>
    }
}

export default ItemStatus;